<template>
  <div>
    <vue-json-to-csv
      :json-data="userJson"
      :labels="csvLabels"
      :csv-title="`completed_order_${new Date().toISOString()}`"
    >
      <v-btn depressed :loading="loading">
        Download CSV
        <v-icon right>mdi-download</v-icon>
      </v-btn>
    </vue-json-to-csv>
  </div>
</template>

<script>
import VueJsonToCsv from "vue-json-to-csv";
export default {
  components: { VueJsonToCsv },
  props: {
    items: {
      type: Array
    },
    loading: {
      type: Boolean
    },
    headers: {
      type: Array
    }
  },
  computed: {
    userJson() {
      const orders = this.items;
      const items = orders.map((elem) => {
        delete elem.contributors;
        function removeIfNull(val) {
          return val;
        }
        elem.createdAt = this.dateFormat(elem.createdAt);
        elem.effectiveDate = this.dateFormat(elem.effectiveDate);
        elem.sellerSignedAt = this.dateFormat(elem.sellerSignedAt);
        elem.price = removeIfNull(elem.price);
        elem.sellerPaypalAccount = removeIfNull(elem.sellerPaypalAccount);
        elem.sellerFPS = removeIfNull(elem.sellerFPS);
        elem.sellerBankCode = removeIfNull(elem.sellerBankCode);
        elem.sellerBankAccountNumber = removeIfNull(elem.sellerBankAccountNumber);
        elem.sellerBankAccountName = removeIfNull(elem.sellerBankAccountName);

        elem.buyerFPS = removeIfNull(elem.buyerFPS);
        elem.buyerBankCode = removeIfNull(elem.buyerBankCode);
        elem.buyerBankAccountNumber = removeIfNull(elem.buyerBankAccountNumber);
        elem.buyerBankAccountName = removeIfNull(elem.buyerBankAccountName);
        elem.orderStage = removeIfNull(elem.orderStage);
        elem.orderType = removeIfNull(elem.orderType);
        elem.orderStatus = removeIfNull(elem.orderStatus);

        elem.buyerPaypalAccount = removeIfNull(elem.buyerPaypalAccount);
        elem.sellerIncome = elem.price ? this.roundToTwo(elem.price - elem.price * this.transactionFee) : " ";

        if (!this.loadedPaypal) {
          delete elem.paypalId;
          delete elem.paypalStatus;
          delete elem.paypalCreatedAt;
          delete elem.paypalCurrencyCode;
        }

        return elem;
      });
      return items;
    },
    csvLabels() {
      let csvLabels = {};
      this.headers.forEach((e) => {
        if (!this.loadedPaypal) {
          if (
            e.value === "paypalId" ||
            e.value === "paypalStatus" ||
            e.value === "paypalCreatedAt" ||
            e.value === "paypalCurrencyCode"
          ) {
            return;
          }
        }
        csvLabels[e.value] = { title: e.text };
      });
      return csvLabels;
    }
  },
  methods: {
    dateFormat(date) {
      if (date === "" || date === " " || !date) {
        return " ";
      }
      try {
        return new Date(date).toISOString().substr(0, 10) + " " + new Date(date).toISOString().substr(11, 8);
      } catch (e) {
        console.log(date);
      }
    },
    roundToTwo(num) {
      return +(Math.round(num + "e+2") + "e-2");
    }
  }
};
</script>

<style></style>

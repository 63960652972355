<template>
  <div>
    <MessageBox
      v-model="messageBox.state"
      :message-content="messageBox.content"
      :message-class="messageBox.cssClass"
      :destination="messageBox.destination"
    />
    <div id="displayCard">
      <v-card min-width="600px" class="pa-10">
        <h1>Landing Info</h1>
        <div class="searchToolbar">
          <v-text-field
            v-model="searchInput"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
          <div class="ma-3">
            <!-- <vue-json-to-csv :json-data="data" :labels="csvLabels">
              <v-btn depressed>
                Download CSV
                <v-icon right>mdi-download</v-icon>
              </v-btn>
            </vue-json-to-csv> -->
            <v-btn color="primary" dark depressed @click="openEditDialog(null)"> New Item </v-btn>
            <v-dialog v-model="dialog" persistent max-width="1200px">
              <v-card>
                <v-card-title>
                  <span class="headline">Landing Info</span>
                </v-card-title>

                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" lg="4">
                        <div style="border: 1px solid #dddddd; border-radius: 10px; padding: 10px">
                          <h3>English</h3>
                          <v-row>
                            <v-col cols="12">
                              <v-text-field label="Title" v-model="title.en"></v-text-field>
                            </v-col>
                            <v-col cols="12">
                              <v-textarea v-model="body.en" v-if="directHTML" outlined></v-textarea>
                              <tip-tap-input v-model="body.en" v-else />
                            </v-col>
                            <v-col cols="12">
                              <v-btn @click="preview('en')" depressed color="primary" rounded>Preview</v-btn>
                            </v-col>
                          </v-row>
                        </div>
                      </v-col>
                      <v-col cols="12" lg="4">
                        <div style="border: 1px solid #dddddd; border-radius: 10px; padding: 10px">
                          <h3>繁體中文</h3>
                          <v-row>
                            <v-col cols="12">
                              <v-text-field label="Title" v-model="title.zhHant"></v-text-field>
                            </v-col>
                            <v-col cols="12">
                              <v-textarea v-model="body.zhHant" v-if="directHTML" outlined></v-textarea>
                              <tip-tap-input v-model="body.zhHant" v-else />
                            </v-col>
                            <v-col cols="12">
                              <v-btn @click="preview('zhHant')" depressed color="primary" rounded>Preview</v-btn>
                            </v-col>
                          </v-row>
                        </div>
                      </v-col>
                      <v-col cols="12" lg="4">
                        <div style="border: 1px solid #dddddd; border-radius: 10px; padding: 10px">
                          <h3>簡體中文</h3>
                          <v-row>
                            <v-col cols="12">
                              <v-text-field label="Title" v-model="title.zhHans"></v-text-field>
                            </v-col>

                            <v-col cols="12">
                              <v-textarea v-model="body.zhHans" v-if="directHTML" outlined></v-textarea>
                              <tip-tap-input v-model="body.zhHans" v-else />
                            </v-col>
                            <v-col cols="12">
                              <v-btn @click="preview('zhHans')" depressed color="primary" rounded>Preview</v-btn>
                            </v-col>
                          </v-row>
                        </div>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-select
                          label="Class"
                          v-model="itemClass"
                          :items="itemClassOptions"
                          item-value="value"
                          item-text="label"
                        ></v-select>
                      </v-col>
                    </v-row>

                    <div class="d-flex align-center justify-center">
                      <input
                        type="file"
                        ref="file"
                        name="file"
                        @change="uploadCoverImage($event.target.name, $event.target.files)"
                      />
                      <v-btn @click="removeImage" text color="primary" v-if="coverImageFileDescriptor"
                        >Remove image</v-btn
                      >
                    </div>
                    <image-from-file-descriptor
                      v-if="coverImageFileDescriptor"
                      :fileDescriptor="coverImageFileDescriptor"
                      :thumbnail="false"
                      height="500"
                    >
                    </image-from-file-descriptor>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-switch v-model="directHTML" label="Use HTML directly"></v-switch>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="dialog = false"> Close </v-btn>
                  <v-btn color="blue darken-1" text @click="createNewInfo"> Save </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="previewDialog" style="height: 800px" persistent>
              <v-card>
                <div class="d-flex justify-space-between px-3 pt-3 align-center">
                  <h5 style="color: #aaaaaa">Preview</h5>
                  <v-btn color="primary" depressed icon @click="closePreview"><v-icon>mdi-close</v-icon></v-btn>
                </div>
                <news-template :news="previewNews"></news-template
              ></v-card>
            </v-dialog>
            <v-btn color="secondary" class="ml-3" dark depressed @click="initialize"> Refresh </v-btn>
          </div>
        </div>

        <v-data-table
          :headers="headers.filter((header) => header.inTable)"
          :items="data"
          :search="searchInput"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
        >
          <template v-slot:item.class="{ item }">
            {{ item.landingInfo.class }}
          </template>
          <template v-slot:item.createdAt="{ item }">
            {{
              new Date(item.createdAt).toLocaleString("en-GB", {
                day: "numeric",
                month: "short",
                year: "numeric",
                hour: "numeric",
                minute: "2-digit"
              })
            }}
          </template>

          <template v-slot:item.content="{ item }">
            <!-- <v-btn icon>
              <v-icon color="primary" v-if="item.workStatus === 2 && !item.isHidden" @click="goToBazaar(item.workid)">
                mdi-earth
              </v-icon>
            </v-btn> -->
            <v-dialog v-model="item.viewInfo" @click:outside="$i18n.locale = initialLang" width="800">
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on" color="primary"> <v-icon>mdi-table-of-contents</v-icon> </v-btn>
              </template>

              <v-card>
                <div class="d-flex flex-row justify-space-between pa-3">
                  <v-select
                    @change="$i18n.locale = $event"
                    style="max-width: 300px"
                    :items="$i18n.availableLocales"
                  ></v-select>
                </div>
                <news-template :news="item" />
              </v-card>
            </v-dialog>
          </template>
          <template v-slot:item.edit="{ item }">
            <v-btn icon @click="openEditDialog(item)"><v-icon>mdi-pencil</v-icon></v-btn>
          </template>
          <template v-slot:item.delete="{ item }">
            <v-btn icon @click="deleteInfo(item.infoid)"><v-icon>mdi-delete</v-icon></v-btn>
          </template>
        </v-data-table>
      </v-card>
    </div>
  </div>
</template>

<script>
// import AddArtwork from "@/components/Platform/AddArtwork";

import PlatformLayout from "@/layouts/Platform";
import { createNewLandingInfo } from "@/services/adminService";
import MessageBox from "@/components/Common/MessageBox";
import config from "@/config.js";
import { getLandingInfo } from "../../../services/workService";
import TipTapInput from "../../../components/TipTapInput.vue";
import { uploadCoverPhotoToBFS } from "../../../services/fileService";
import { deleteLandingInfo, editLandingInfo } from "../../../services/adminService";
import NewsTemplate from "../../../components/Home/NewsTemplate.vue";
import ImageFromFileDescriptor from "../../../components/Common/ImageFromFileDescriptor.vue";
// import VueJsonToCsv from "vue-json-to-csv";

export default {
  name: "AdminLandingInfoFunctions",
  components: {
    MessageBox,
    TipTapInput,
    NewsTemplate,
    ImageFromFileDescriptor
    // VueJsonToCsv
  },
  data: () => ({
    files: [],
    expand: false,
    openIdx: -1,
    sortBy: "createdAt",
    sortDesc: true,
    headers: [
      {
        text: "Info ID",
        value: "infoid",
        align: "left",
        sortable: false,
        inputField: false,
        inTable: true
      },
      {
        text: "Title",
        align: "left",
        sortable: true,
        value: "landingInfo.title.en",
        inTable: true
      },
      {
        text: "Class",
        align: "left",
        sortable: true,
        value: "class",
        inTable: true
      },
      {
        text: "Created At",
        align: "left",
        sortable: true,
        value: "createdAt",
        inTable: true
      },
      {
        text: "Content",
        align: "left",
        sortable: true,
        value: "content",
        inTable: true
      },
      {
        text: "Edit",
        value: "edit",
        align: "center",
        sortable: false,
        inputField: false,
        inTable: true
      },
      {
        text: "Delete",
        value: "delete",
        align: "center",
        sortable: false,
        inputField: false,
        inTable: true
      }
    ],

    itemClass: 0,
    itemClassOptions: [
      {
        value: 0,
        label: "News"
      },
      {
        value: 1,
        label: "Event"
      }
    ],
    infoid: null,
    title: {
      en: "",
      zhHant: "",
      zhHans: ""
    },
    body: {
      en: "<p> Insert content here</p>",
      zhHant: "<p>請輸入內容</p>",
      zhHans: "<p>请输入内容</p>"
    },
    directHTML: true,
    locale: "en",
    coverImage: null,
    searchField: null,
    searchInput: null,
    data: [],
    messageBox: {
      state: false,
      content: "",
      cssClass: "error",
      destination: ""
    },
    dialog: false,
    previewDialog: false,
    viewItem: "",
    initialLang: null,
    coverImageFileDescriptor: null,
    newItem: false
  }),
  mounted() {
    this.initialize();
  },
  async created() {
    this.$emit(`update:layout`, PlatformLayout);
    this.initialLang = this.$i18n.locale;
    const role = this.$session.get("role");
    if (role !== "AN") {
      this.$router.push({ name: "Not Found" });
      return;
    }

    this.role = this.$session.get("role");
    if (this.data.length === 0 && this.role === "member") {
      this.timer = setInterval(() => {
        this.role = this.$session.get("role");

        if (this.role !== "member") {
          clearInterval(this.timer);
          this.initialize();
        }
      }, 1000);
    } else {
      // to deal with refresh
      clearInterval(this.timer);
      this.initialize();
    }
  },

  methods: {
    async initialize() {
      //  Get request to server
      const token = this.$session.get("token");
      const result = await getLandingInfo(this.$axios, config, token);

      if (result.status === 200) {
        this.data = result.data;
      } else if (result.status === 500) {
        this.messageBox.content = this.$t("dialogMsg.myCollection.blockchainFail");
        this.messageBox.state = true;
        this.messageBox.destination = "";
        this.$store.commit("showProgressLine", false);
      } else {
        this.messageBox = {
          content: `Failed to retrieve landing information. Error code: ${result.status}. Error message: ${result.message}.`,
          cssClass: "error",
          state: true,
          destination: ""
        };
      }
    },
    async uploadCoverImage(fieldName, file) {
      let coverImageFileDescriptor;
      let imageFile = file[0];
      let formData = new FormData();
      let imageURL = URL.createObjectURL(imageFile);
      formData.append(fieldName, imageFile);
      // Emit the FormData and image URL to the parent component
      const body = { formData, imageURL, size: imageFile.size };
      const token = this.$session.get("token");
      const coverImageRequest = await uploadCoverPhotoToBFS(this.$axios, config, body.formData, token);

      if (coverImageRequest.status === 200) {
        coverImageFileDescriptor = coverImageRequest.data.fileDescriptor;
        this.coverImageFileDescriptor = coverImageFileDescriptor;
      } else if (coverImageRequest.status === 500) {
        this.messageBox.content = this.$t("dialogMsg.myCollection.blockchainFail");
        this.messageBox.state = true;
        this.messageBox.destination = "";
        this.$store.commit("showProgressLine", false);
      } else {
        this.messageBox.content = `The cover picture cannot be uploaded.`;
        this.messageBox.state = true;
        this.messageBox.cssClass = "error";
        this.messageBox.destination = "";
        return;
      }
    },

    async createNewInfo() {
      const landingInfo = JSON.stringify({
        title: this.title,
        body: this.body,
        class: this.itemClass,
        coverImage: this.coverImageFileDescriptor
      });
      const token = this.$session.get("token");
      let result;
      if (this.newItem) {
        result = await createNewLandingInfo(this.$axios, config, token, landingInfo);
      } else {
        result = await editLandingInfo(this.$axios, config, token, landingInfo, this.infoid);
      }

      if (result.status === 200) {
        if (this.newItem) {
          this.messageBox = {
            content: `New landing info created`,
            cssClass: "message",
            state: true,
            destination: ""
          };
        } else {
          this.messageBox = {
            content: `Landing info edited`,
            cssClass: "message",
            state: true,
            destination: ""
          };
        }

        this.initialize();
      } else if (result.status === 500) {
        this.messageBox.content = this.$t("dialogMsg.myCollection.blockchainFail");
        this.messageBox.state = true;
        this.messageBox.destination = "";
        this.$store.commit("showProgressLine", false);
      } else {
        this.messageBox = {
          content: `Failed to create new landing info. Error code: ${result.status}. Error message: ${result.data.message}.`,
          cssClass: "error",
          state: true,
          destination: ""
        };
      }

      this.dialog = false;
    },
    removeImage() {
      this.coverImageFileDescriptor = null;
    },
    preview(lang) {
      this.$i18n.locale = lang;
      this.previewDialog = true;
    },
    closePreview() {
      this.previewDialog = false;
      this.$i18n.locale = this.initialLang;
    },
    async deleteInfo(infoid) {
      const confirmDelete = confirm(`Confirm to delete landing info ${infoid}?`);
      if (confirmDelete) {
        const token = this.$session.get("token");
        const result = await deleteLandingInfo(this.$axios, config, token, infoid);
        if (result.status === 200) {
          this.messageBox = {
            content: `Info ${infoid} deleted`,
            cssClass: "message",
            state: true,
            destination: ""
          };
          this.initialize();
        } else if (result.status === 500) {
          this.messageBox.content = this.$t("dialogMsg.myCollection.blockchainFail");
          this.messageBox.state = true;
          this.messageBox.destination = "";
          this.$store.commit("showProgressLine", false);
        } else {
          this.messageBox = {
            content: `Failed to delete landing info. Error code: ${result.status}. Error message: ${result.data.message}.`,
            cssClass: "message",
            state: true,
            destination: ""
          };
        }
      }
    },
    openEditDialog(item) {
      if (!item) {
        this.newItem = true;
        this.infoid = null;
        this.dialog = true;
        this.title = {
          en: "",
          zhHant: "",
          zhHans: ""
        };
        this.body = {
          en: "<p> Insert content here</p>",
          zhHant: "<p>請輸入內容</p>",
          zhHans: "<p>请输入内容</p>"
        };
        this.itemClass = 0;
        this.coverImageFileDescriptor = null;
      } else {
        this.newItem = false;
        this.infoid = item.infoid;
        this.dialog = true;
        this.title = item.landingInfo.title;
        this.body = item.landingInfo.body;
        this.itemClass = item.landingInfo.itemClass;
        this.coverImageFileDescriptor = item.landingInfo.coverImage;
      }
    }
  },
  computed: {
    previewNews() {
      const news = { createdAt: new Date().getTime() };
      news.landingInfo = {
        title: this.title,
        body: this.body,
        class: this.itemClass,
        coverImage: null,
        hideCoverImage: true
      };
      return news;
    }
  }
};
</script>

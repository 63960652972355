<template>
  <div>
    <MessageBox
      v-model="messageBox.state"
      :message-content="messageBox.content"
      :message-class="messageBox.cssClass"
      :destination="messageBox.destination"
    />
    <div v-if="viewItem">
      <artwork-display-dialog v-model="workDialog" :item="viewItem" :key="viewItem.workid" />
    </div>
    <div id="displayCard">
      <v-card min-width="600px" class="pa-10">
        <h1>Orders</h1>
        <div class="searchToolbar">
          <v-text-field v-model="searchInput" append-icon="mdi-magnify" label="Search"></v-text-field>
          <v-text-field v-model="transactionFee" label="Transaction Fee" class="mx-4" style="max-width: 200px" />
          <div class="my-3" style="display: flex">
            <v-btn depressed @click="initialize" :disabled="loading" class="mr-2"> Update Transaction Fee </v-btn>
            <v-btn depressed @click="loadPaypalInfo" :disabled="loading" class="mr-2"> Load Paypal </v-btn>
            <download-order-json :items="items" :headers="headers" :loading="loading" />
          </div>
        </div>

        <v-data-table
          :headers="headers.filter((header) => header.inTable)"
          :items="filteredItems"
          :search="searchInput"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :loading="loading"
          dense
        >
          <template v-slot:item.viewWork="{ item }">
            <v-btn icon @click="viewWork(item.workid)" color="primary">
              <v-icon>mdi-earth</v-icon>
            </v-btn>
          </template>
        </v-data-table>
        <div><v-checkbox label="Show completed orders only" v-model="completedOrdersOnly"></v-checkbox></div>
        <div style="display: flex; align-items: center" v-if="paypalLoading && !loading">
          <v-progress-circular indeterminate color="primary" small></v-progress-circular>
          <h4 class="ml-3">Paypal details loading in the background</h4>
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
// import AddArtwork from "@/components/Platform/AddArtwork";

import PlatformLayout from "@/layouts/Platform";

import MessageBox from "@/components/Common/MessageBox";
import config from "@/config.js";

import { getAllOrders, getAllUsers, getPaypalDetails, getPaypalId } from "../../../services/adminService";
import { displayWorkByWorkid } from "../../../services/workService";
import ArtworkDisplayDialog from "@/components/Common/ArtworkDisplayDialog.vue";
import DownloadOrderJson from "../../../components/Admin/DownloadOrderJson.vue";
export default {
  name: "AdminOrderFunctions",
  components: {
    MessageBox,
    ArtworkDisplayDialog,
    DownloadOrderJson
  },
  data: () => ({
    files: [],
    expand: false,
    openIdx: -1,
    sortBy: "createdAt",
    sortDesc: true,
    headers: [
      {
        text: "View Work",
        align: "left",
        value: "viewWork",
        inTable: true
      },
      {
        text: "Created At",
        align: "left",
        value: "createdAt",
        inTable: true
      },
      {
        text: "Effective Date",
        align: "left",
        value: "effectiveDate",
        inTable: true
      },
      {
        text: "Seller Signed At",
        align: "left",
        value: "sellerSignedAt",
        inTable: true
      },
      {
        text: "Buyer",
        align: "left",
        sortable: true,
        value: "buyer",
        inTable: true
      },
      {
        text: "Buyer Paypal",
        align: "left",
        sortable: true,
        value: "buyerPaypalAccount",
        inTable: true
      },
      {
        text: "Buyer FPS",
        align: "left",
        sortable: true,
        value: "buyerFPS",
        inTable: true
      },
      {
        text: "Buyer Bank Code",
        align: "left",
        sortable: true,
        value: "buyerBankCode",
        inTable: true
      },
      {
        text: "Buyer Bank Account No.",
        align: "left",
        sortable: true,
        value: "buyerBankAccountNumber",
        inTable: true
      },
      {
        text: "Buyer Bank Account Name",
        align: "left",
        sortable: true,
        value: "buyerBankAccountName",
        inTable: true
      },
      {
        text: "Seller",
        align: "left",
        sortable: false,
        value: "seller",
        inTable: true
      },
      {
        text: "Seller Paypal",
        align: "left",
        sortable: true,
        value: "sellerPaypalAccount",
        inTable: true
      },
      {
        text: "Seller FPS",
        align: "left",
        sortable: true,
        value: "sellerFPS",
        inTable: true
      },
      {
        text: "Seller Bank Code",
        align: "left",
        sortable: true,
        value: "sellerBankCode",
        inTable: true
      },
      {
        text: "Seller Bank Account No.",
        align: "left",
        sortable: true,
        value: "sellerBankAccountNumber",
        inTable: true
      },
      {
        text: "Seller Bank Account Name",
        align: "left",
        sortable: true,
        value: "sellerBankAccountName",
        inTable: true
      },
      {
        text: "Order ID",
        align: "left",
        value: "orderid",
        inTable: true
      },
      {
        text: "Work ID",
        align: "left",
        value: "workid",
        inTable: true
      },
      {
        text: "Paypal ID",
        align: "left",
        value: "paypalId",
        paypal: true,
        inTable: false
      },
      {
        text: "Paypal Status",
        align: "left",
        value: "paypalStatus",
        paypal: true,
        inTable: false
      },
      {
        text: "Paypal Transaction Time",
        align: "left",
        value: "paypalCreatedAt",
        paypal: true,
        inTable: false
      },
      {
        text: "Paypal Currency",
        align: "left",
        value: "paypalCurrencyCode",
        paypal: true,
        inTable: false
      },
      {
        text: "Order Stage",
        align: "left",
        value: "orderStage",
        inTable: true
      },
      {
        text: "Order Status",
        align: "left",
        value: "orderStatus",
        inTable: true
      },
      {
        text: "Order Type",
        align: "left",
        value: "orderType",
        inTable: true
      },
      {
        text: "Price (US$)",
        align: "left",
        value: "priceUSD",
        inTable: true
      },
      {
        text: "Fee (US$)",
        align: "left",
        value: "feeUSD",
        inTable: true
      },
      {
        text: "Net to Seller (US$)",
        align: "left",
        value: "sellerIncomeUSD",
        inTable: true
      },
      {
        text: "Price (HK$)",
        align: "left",
        value: "priceHKD",
        inTable: true
      },

      {
        text: "Fee (HK$)",
        align: "left",
        value: "feeHKD",
        inTable: true
      },
      {
        text: "Net to Seller (HK$)",
        align: "left",
        value: "sellerIncomeHKD",
        inTable: true
      }
    ],
    statusCode: [
      {
        label: "AN",
        roleType: 0,
        color: "#2EB73A"
      },
      {
        label: "Premier Member",
        roleType: 2,
        color: "#B3A7D3"
      },
      {
        label: "Member",
        roleType: 1,
        color: "#F1951B"
      }
    ],
    items: [],
    addrList: {},
    editedIndex: -1,
    timer: null,
    searchField: null,
    searchInput: null,
    addingArtwork: false,
    pageNumber: 1,
    itemsPerPage: 5,
    pageCount: 1,
    messageBox: {
      state: false,
      content: "",
      cssClass: "error",
      destination: ""
    },
    dialog: false,
    viewItem: null,
    workDialog: false,
    autoApprove: false,
    autoApproveDialog: false,
    password: "",
    show1: false,
    transactionFee: 0.15,
    completedOrdersOnly: false,
    loading: false,
    loadedPaypal: false,
    paypalLoading: false,
    allUsers: []
  }),
  computed: {
    filteredItems() {
      if (this.completedOrdersOnly) {
        return this.items.filter((elem) => elem.orderStatus === 1);
      } else {
        return this.items;
      }
    }
  },
  mounted() {
    this.transactionFee = config.transaction_fee;
    this.initialize();
  },
  async created() {
    this.$emit(`update:layout`, PlatformLayout);

    const role = this.$session.get("role");
    if (role !== "AN") {
      this.$router.push({ name: "Not Found" });
      return;
    }
  },
  updated() {
    // this.role = this.$session.get('role')
  },
  methods: {
    getStatusCode(item) {
      return this.statusCode.find((obj) => obj.roleType === item.roleType);
    },
    getStatusColor(item) {
      let status = this.statusCode.find((obj) => obj.roleType === item.roleType);
      return "color: " + status.color + "; margin: 0;";
    },

    async initialize() {
      this.loading = true;

      //  Get request to server
      const token = this.$session.get("token");
      let statusCode;
      const result = getAllOrders(this.$axios, config, token).then((res) => {
        if (res) {
          statusCode = res.status;
          return res.data.data;
        } else {
          statusCode = 200;
          return null;
        }
      });
      const users = getAllUsers(this.$axios, config, token).then((res) => {
        return res.data.users;
      });

      const requestOutput = await Promise.all([result, users]).then((res) => {
        return res;
      });

      if (statusCode === 200) {
        this.loading = false;

        this.allUsers = requestOutput[1];
        this.items = requestOutput[0]
          .map((elem) => {
            return this.fillInRoleInfo(elem);
          })
          .map((elem) => {
            return this.priceMapping(elem);
          });
      } else {
        this.messageBox = {
          content: `Failed to retrieve user information. Error code: ${result.status}. Error message: ${result.message}.`,
          cssClass: "error",
          state: true,
          destination: ""
        };
      }
      this.loading = false;
    },
    fillInRoleInfo(order) {
      order.buyerFPS = this.getUserRoleInfo(order, "address", "buyerAddress", "fpsAccount");
      order.sellerFPS = this.getUserRoleInfo(order, "address", "sellerAddress", "fpsAccount");
      order.buyerBankCode = this.getUserRoleInfo(order, "address", "buyerAddress", "bankAccount", "bankCode");
      order.buyerBankAccountNumber = this.getUserRoleInfo(
        order,
        "address",
        "buyerAddress",
        "bankAccount",
        "accountNumber"
      );
      order.buyerBankAccountName = this.getUserRoleInfo(order, "address", "buyerAddress", "bankAccount", "accountName");
      order.sellerBankCode = this.getUserRoleInfo(order, "address", "sellerAddress", "bankAccount", "bankCode");
      order.sellerBankAccountNumber = this.getUserRoleInfo(
        order,
        "address",
        "sellerAddress",
        "bankAccount",
        "accountNumber"
      );
      order.sellerBankAccountName = this.getUserRoleInfo(
        order,
        "address",
        "sellerAddress",
        "bankAccount",
        "accountName"
      );
      return order;
    },
    orderInfoMapping(order) {
      order.orderType = this.orderTypeMapping(order.orderType);
      order.orderStage = this.orderStageMapping(order.orderStage);
      order.orderStatus = this.orderStatusMapping(order.orderStatus);
      return order;
    },
    priceMapping(order) {
      order.priceUSD = order.price ? order.price : " ";
      order.sellerIncomeUSD = this.sellerIncomeMapping(order.price);
      order.feeUSD = this.feeMapping(order.price);

      // HKD mapping
      order.priceHKD = this.USDtoHKD(order.priceUSD);
      order.sellerIncomeHKD = this.USDtoHKD(order.sellerIncomeUSD);
      order.feeHKD = this.USDtoHKD(order.feeUSD);

      return order;
    },
    getUserRoleInfo(order, userField, orderField, getField, deepField) {
      const matchUser = this.allUsers.find((elem) => {
        return elem[userField] === order[orderField];
      });

      if (!matchUser) {
        return null;
      }
      if (!matchUser.roleInfo) {
        return null;
      }

      if (Object.hasOwnProperty.call(matchUser.roleInfo, getField)) {
        if (!deepField) {
          return matchUser.roleInfo[getField];
        } else {
          return matchUser.roleInfo[getField][deepField];
        }
      } else {
        return null;
      }
    },
    async viewWork(workid) {
      const result = await displayWorkByWorkid(this.$axios, config, workid, this.$session.get("token"));
      this.viewItem = result.data;
      this.workDialog = true;
    },
    async loadPaypalInfo() {
      this.paypalLoading = true;
      const users = this.allUsers;
      const token = this.$session.get("token");
      const paypalIdItems = this.items.map((elem) => {
        return getPaypalId(this.$axios, config, token, elem.orderid);
      });
      const paypalIdArray = await Promise.all(paypalIdItems).then((res) => {
        return res.map((elem) => {
          if (elem) {
            return getPaypalDetails(this.$axios, config, token, elem);
          } else {
            return null;
          }
        });
      });

      const paypalDetailsArray = await Promise.all(paypalIdArray);

      this.items = this.items.map((elem, i) => {
        if (paypalDetailsArray[i]) {
          elem.paypalId = paypalDetailsArray[i].data.data.paypalOrderid;
          elem.paypalStatus = paypalDetailsArray[i].data.data.paypalStatus;
          elem.paypalCurrencyCode = paypalDetailsArray[i].data.data.paypalCurrencyCode;
          elem.paypalCreatedAt = this.dateFormat(new Date(paypalDetailsArray[i].data.data.paypalCreatedAt));
        } else {
          elem.paypalId = " ";
          elem.paypalStatus = " ";
          elem.paypalCreatedAt = " ";
          elem.paypalCurrencyCode = " ";
        }

        const sellerInfo = users.find((user) => {
          return user.address === elem.sellerAddress;
        });
        if (sellerInfo) {
          elem.sellerRoleInfo = sellerInfo.roleInfo;
          elem.sellerPaypalAccount = sellerInfo.roleInfo.paypalAccount;
        }

        const buyerInfo = users.find((user) => {
          return user.address === elem.buyerAddress;
        });
        if (buyerInfo) {
          elem.buyerRoleInfo = buyerInfo.roleInfo;
          elem.buyerPaypalAccount = buyerInfo.roleInfo.paypalAccount;
        }
        return elem;
      });
      this.paypalLoading = false;
      this.loadedPaypal = true;
      this.headers = this.headers.map((elem) => {
        if (elem.paypal) {
          elem.inTable = true;
        }
        return elem;
      });
    },
    translateArray(item) {
      const content = this.$t(item);
      const result = content.replace(/['"]+/g, "").replace(/['"]+/g, "").replace(/['"]+/g, "").split(",");
      return result;
    },
    dateFormat(date) {
      if (date === "" || date === " " || !date) {
        return " ";
      }
      try {
        return new Date(date).toISOString().substr(0, 10) + " " + new Date(date).toISOString().substr(11, 8);
      } catch (e) {
        console.log(date);
      }
    },
    roundToTwo(num) {
      // return num;
      return +(Math.round(num + "e+2") + "e-2");
    },

    orderTypeMapping(n) {
      if (!n && n !== 0) {
        return "NA";
      }
      const orderTypeEnum = ["Transfer", "License"];
      return orderTypeEnum[n];
    },
    orderStageMapping(n) {
      if (!n && n !== 0) {
        return "NA";
      }
      const orderStageEnum = [
        "Order Started",
        "Buyer Updated Terms",
        "Seller Approved Terms",
        "Seller Signed",
        "Buyer Settled",
        "Buyer Paid"
      ];
      return orderStageEnum[n];
    },
    orderStatusMapping(n) {
      if (!n && n !== 0) {
        return "NA";
      }
      const orderStatusEnum = ["Active", "Completed", "Inactive", "Expired"];
      return orderStatusEnum[n];
    },
    sellerIncomeMapping(price) {
      const sellerIncome = this.roundToTwo(price - price * this.transactionFee);
      if (!sellerIncome || isNaN(sellerIncome)) {
        return " ";
      } else {
        return sellerIncome;
      }
    },
    feeMapping(price) {
      const fee = this.roundToTwo(price * this.transactionFee);
      if (!price || isNaN(fee)) {
        return " ";
      } else {
        return fee;
      }
    },
    USDtoHKD(price) {
      const hkd = this.roundToTwo(price * 7.85);
      if (!price || isNaN(hkd)) {
        return " ";
      } else {
        return hkd;
      }
    }
  }
};
</script>
